<template>
    <div class="playletModule">
        <div class="header">
          <img @click="$router.go(-1)" class="leftBackIcon" src="@/assets/png/leftBackIcon.png"  alt="">
          <van-tabs class="tabs" v-model="active" @change="change">
            <van-tab title="短视频">
<!--                <ShortVideoItem></ShortVideoItem>-->
            </van-tab>
            <van-tab title="短剧">
<!--                <PlayletItem></PlayletItem>-->
            </van-tab>
            <van-tab title="追剧">
<!--              <BingeWatch></BingeWatch>-->
            </van-tab>
          </van-tabs>
        </div>
        <div class="main">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
    </div>
</template>

<script>
  import BingeWatch from './bingeWatch/index.vue'
  export default {
    name: 'PlayletModule',
    components: {
      BingeWatch,
    },
    data(){
      return {
        active: 0,
      }
    },
    activated() {
      let path = this.$route.path;
      if (path == '/playletModule/shortVideo') {
        this.active = 0;
      }

      if (path == '/playletModule/playlet') {
        this.active = 1;
      }

      if (path == '/playletModule/bingeWatch') {
        this.active = 2;
      }
      this.change(this.active);
    },
    mounted() {
      let path = this.$route.path;
      if (path == '/playletModule/shortVideo') {
        this.active = 0;
      }

      if (path == '/playletModule/playlet') {
        this.active = 1;
      }

      if (path == '/playletModule/bingeWatch') {
        this.active = 2;
      }
      this.change(this.active);
    },
    methods:{
      clickTab(path){
        this.$router.replace(path);
      },
      change(index){
        if (index === 0) {
          this.clickTab('/playletModule/shortVideo');
        }
        if (index === 1) {
          this.clickTab('/playletModule/playlet');
        }
        if (index === 2) {
          this.clickTab('/playletModule/bingeWatch');
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .playletModule{
    width: 100%;
    height: 100%;
    background: #0C0C0C;

    .header{
      .leftBackIcon{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 12px;
        left: 10px;
        z-index: 1;
      }
    }

    .tabs{
      //padding: 0 16px;
      padding-top: 2px;

      /deep/ .van-tabs__wrap{
        background-image: none !important;
        padding: 4px;
        background: #161616;
        border-radius: 8px;
        height: 33px;
        border: solid 1px #252525;
        margin-left: 50px;
        margin-right: 30px;
      }

      /deep/ .van-tabs__nav{
        background: transparent;
      }

      /deep/ .van-tab{
        font-size: 10px;
        color: rgba(245, 245, 245, 0.58);
        font-weight: 900;
        padding: 0;
        border:solid 1px #161616;
      }

      /deep/ .van-tab--active{
        background: #212121;
        border-radius: 8px;
        border: solid 1px rgba(255, 255, 255, 0.05);
      }

      /deep/ .videoListItem{
        height: 100vh;
        overflow-y: visible;
      }

      /deep/ .van-tabs__line{
        display: none;
      }
    }
  }
</style>